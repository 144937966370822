
.horizontal-card-container {
    max-width: 1200px;
    margin: 24px auto;
    padding: 0 16px;
    position: relative;
  }
  
  .horizontal-card-heading {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 16px;
    color: #333333;
  }

  .horizontal-card-wrapper {
    position: relative;
  }
 
  .horizontal-card-content {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    padding-bottom: 32px;
    scroll-snap-type: x proximity;
  }
  
  .horizontal-card-content::-webkit-scrollbar {
    display: none;
  }

  .scroll-button {
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 8px;
    font-size: 1.25rem;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.3s;
    border: none;
  }
  
  .scroll-button:hover {
    background-color: #f3f4f6;
  }
  
  .scroll-button.left {
    left: 0;
  }
  
  .scroll-button.right {
    right: 0;
  }
  

  .product-card {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    width: 280px;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
  }
  

  .product-image {
    background-color: #f5f5f5;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .product-image-content {
    max-height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease;
  }
  
  .product-image-content:hover {
    transform: scale(1.05);
  }
  
  .product-info {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
  }
  
  .product-name {
    font-size: 16px;
    font-weight: 600;
    color: #D4AF37;
    margin: 0;
  }
  
  .product-brand {
    font-size: 14px;
    color: #D4AF37;
    margin: 0;
  }
  
  .product-prices {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .product-selling-price {
    color: #D4AF37;
    font-weight: 700;
    font-size: 14px;
  }
  
  .product-original-price {
    color: #D4AF37;
    text-decoration: line-through;
    font-size: 14px;
  }
  
  .product-actions {
    margin-top: auto;
    display: flex;
    justify-content: flex-start;
  }
  
  .add-to-cart-btn {
    background-color: #C0C0C0;
    color: #D4AF37;
    padding: 10px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none;
    font-style: italic;
  }
  
  .add-to-cart-btn:hover {
    background-color:#C0C0C0 ;
  }
  
  .loading {
    animation: pulse 1.5s ease-in-out infinite;
  }
  
  .product-image-placeholder {
    background-color: #e2e8f0;
    height: 140px;
    width: 100%;
  }
  
  .loading-placeholder {
    background-color: #e2e8f0;
    border-radius: 4px;
  }
  
  @keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
  }
  
  @media (max-width: 768px) {
    .horizontal-card-content {
      gap: 8px;
    }
  
    .product-card {
      width: 220px;
    }
  
    .scroll-button.left {
      left: -10px;
    }
  
    .scroll-button.right {
      right: -10px;
    }
  }
  