:root {
    --dorado-suave: #D4AF37;
    --beige-claro: #F5F5DC;
    --blanco-roto: #FAF9F6;
    --gris-perla: #C0C0C0;
    --text-color: #282828;
    --accent-color: #B8982B;
  }
  
  .reservation-container {
    padding: 3rem 2rem;
    background: var(--beige-claro);
    font-family: 'Arial', sans-serif;
    color: var(--text-color);
  }
  
  .reservation-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--dorado-suave);
  }
  
  .reservation-content {
    display: flex;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Lista de servicios */
  .services-list {
    flex: 1;
  }
  
  .section-heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--dorado-suave);
  }
  
  .services-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .service-card {
    background: var(--blanco-roto);
    border: 2px solid transparent;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: border 0.3s ease, transform 0.3s ease;
  }
  
  .service-card:hover {
    border-color: var(--dorado-suave);
    transform: translateY(-5px);
  }
  
  .service-card.selected {
    border-color: var(--dorado-suave);
    background: var(--beige-claro);
  }
  
  .service-name {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: var(--dorado-suave);
  }
  
  .service-brand {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: var(--text-color);
  }
  
  .service-description {
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
    color: var(--gris-perla);
  }
  
  .service-price {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--text-color);
  }
  
  /* Formulario de reserva */
  .reservation-form {
    flex: 1;
    background: var(--blanco-roto);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .form-group {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: var(--text-color);
  }
  
  .form-group input,
  .form-group select {
    padding: 0.75rem;
    border: 1px solid var(--gris-perla);
    border-radius: 4px;
    font-size: 1rem;
    color: var(--text-color);
  }
  
  .reservation-button {
    background-color: var(--dorado-suave);
    color: var(--blanco-roto);
    padding: 12px 24px;
    border: none;
    border-radius: 50px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: block;
    margin: 0 auto;
  }
  
  .reservation-button:hover {
    background-color: var(--accent-color);
    transform: scale(1.05);
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .reservation-content {
      flex-direction: column;
    }
  }
  
  @media (max-width: 480px) {
    .reservation-title {
      font-size: 1.75rem;
    }
    .section-heading {
      font-size: 1.3rem;
    }
    .form-group input,
    .form-group select {
      font-size: 0.9rem;
    }
    .reservation-button {
      font-size: 0.9rem;
      padding: 10px 20px;
    }
  }
  