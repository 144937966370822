
/* Sombra elegante para elementos */
.customShadow {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  }
  
  /*** Tabla de usuarios ***/
  .userTable {
    @apply bg-white rounded-lg shadow-md;
  }
  
  .userTable th {
    @apply border text-lg font-semibold uppercase text-gray-700 p-3;
  }
  
  .userTable td {
    @apply border text-lg text-center text-gray-600 p-3;
  }
  
  /** Scrollbar oculta **/
  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }