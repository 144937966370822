:root {
  --dorado-suave: #D4AF37;
  --beige-claro: #F5F5DC;
  --blanco-roto: #FAF9F6;
  --gris-perla: #C0C0C0;
  --brand-hover: #B8982B;
}

/* Estilos globales */
body {
  background-color: var(--beige-claro);
  color: var(--gris-perla);
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.home-main {
  width: 100%;
  overflow-x: hidden;
}

/* Hero Section */
.hero-section {
  background-color: var(--dorado-suave);
  padding: 60px 20px;
  color: var(--blanco-roto);
  text-align: center;
}

.hero-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

/* About Section */
.about-section {
  padding: 60px 20px;
  text-align: center;
  color: var(--gris-perla);
}

.about-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--dorado-suave);
}

.about-section p {
  font-size: 1.125rem;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
}

.about-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.about-images img {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}

/* Services Section */
.services-section {
  background-color: var(--gris-perla);
  padding: 60px 20px;
  color: var(--blanco-roto);
  text-align: center;
}

.services-section p {
  font-size: 1.125rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Extra Links */
.extra-links {
  text-align: center;
  padding: 40px 20px;
}

.extra-links a {
  text-decoration: none;
  font-size: 1.125rem;
  color: #81C784;
  
  transition: color 0.3s ease;
}

.extra-links a:hover {
  color: var(--gris-perla);
}

/* Products Section */
.products-section {
  padding: 60px 20px;
  text-align: center;
  color: var(--gris-perla);
}

.products-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--dorado-suave);
}

.products-section p {
  font-size: 1.125rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Whatsapp Invite */
.whatsapp-invite-container {
  text-align: center;
  margin: 40px 20px;
}

.whatsapp-invite-link {
  background-color: var(--dorado-suave);
  color: var(--blanco-roto);
  padding: 12px 24px;
  border-radius: 9999px;
  text-decoration: none;
  font-size: 1.125rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.whatsapp-invite-link:hover {
  background-color: var(--brand-hover);
  transform: scale(1.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-section h2 {
    font-size: 2rem;
  }
  .hero-section p,
  .about-section p,
  .products-section p {
    font-size: 1rem;
    padding: 0 10px;
  }
  .about-images {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .hero-section h2 {
    font-size: 1.75rem;
  }
  .hero-section p {
    font-size: 0.95rem;
  }
  .about-section h2 {
    font-size: 1.75rem;
  }
  .products-section h2 {
    font-size: 1.75rem;
  }
}
