.footer-container {
  background-color: #f8f9fa;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
}

.footer-section {
  flex: 1;
  min-width: 250px;
  text-align: center;
  padding: 20px;
}

.footer-section h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #666;
  text-transform: uppercase;
}

.footer-section p,
.footer-section ul {
  font-size: 1rem;
  margin: 8px 0;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-section p span {
  margin-left: 8px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 8px 0;
}

.footer-section ul li a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}
.enlaces_footer {
flex-direction: column;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.social-icons a {
  font-size: 1.8rem;
  color: #000;
}

.social-icons a:hover {
  color: #007bff;
}

.subscription-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subscription-form input {
  padding: 10px;
  width: 250px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.subscription-form button {
  padding: 10px 20px;
  border: none;
  background-color: #D4AF37;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.subscription-form button:hover {
  background-color: #f4c428;
}

.icon {
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 576px) {
  .subscription-form input {
    width: 100%;
  }
  .subscription-form button {
    width: 100%;
  }
}
