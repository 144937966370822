
.uniqueCart-container {
    padding: 40px 20px;
    background-color: #f4f4f4;
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
  }
  

  .uniqueCart-emptyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }
  
  .uniqueCart-emptyMessage {
    font-size: 1.8em;
    color: #6c757d;
    text-align: center;
  }
  

  .uniqueCart-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  

  .uniqueCart-productList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .uniqueCart-productCard {
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 300px;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .uniqueCart-productCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
 
  .uniqueCart-productImage {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .uniqueCart-productImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .uniqueCart-productCard:hover .uniqueCart-productImg {
    transform: scale(1.05);
  }
  
 
  .uniqueCart-deleteButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(220, 53, 69, 0.9);
    border: none;
    color: #ffffff;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .uniqueCart-deleteButton:hover {
    background-color: rgba(220, 53, 69, 1);
  }
  

  .uniqueCart-details {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .uniqueCart-name {
    font-size: 1.5em;
    color: #343a40;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  .uniqueCart-category {
    font-size: 1em;
    color: #6c757d;
    margin-bottom: 15px;
  }
  

  .uniqueCart-quantity {
    margin-top: 10px;
  }
  
  .uniqueCart-quantityInput {
    width: 60px;
    margin-left: 5px;
  }
  
  .uniqueCart-price {
    margin-top: 10px;
  }
  
  .uniqueCart-totalPrice {
    font-size: 1.2em;
    color: #28a745;
    font-weight: bold;
  }
  

  .uniqueCart-summary {
    margin-top: 30px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .uniqueCart-totalAmount {
    font-size: 1.8em;
    color: #343a40;
    margin-bottom: 10px;
  }
  
 
  .uniqueCart-depositContainer {
    margin-top: 20px;
    text-align: center;
  }
  
  .uniqueCart-depositText {
    font-style: italic;
    color: #555;
  }
  
  .uniqueCart-depositAmount {
    margin: 10px 0;
  }
  
  
  .uniqueCart-buttonsContainer {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .uniqueCart-buttonItem {
    flex: 1 1 300px;
    max-width: 350px;
  }
  

  @media (max-width: 768px) {
    .uniqueCart-productList {
      flex-direction: column;
      align-items: center;
    }
    
    .uniqueCart-productCard {
      width: 90%;
    }
    
    .uniqueCart-buttonsContainer {
      flex-direction: column;
      gap: 20px;
    }
  }
  