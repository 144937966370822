.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.image-container {
  margin-bottom: 20px;
}

.car-image {
  max-width: 380px;
  height: auto; 
}

.text-container {
  text-align: center;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  margin-bottom: 10px;
}


.description {
  font-size: 16px;
  margin-bottom: 20px;
}

.link {
  margin-bottom: 20px;
}

.button {
  border-radius: 0.375rem;
  border: 2px solid #fcf75e;
  background-color: #fcf75e;
  color: black;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  margin-top: 2rem; 
}

.button:hover {
  background-color: #000000;
  color: #ffffff;
  font-style: bold; 
}

.gallery-container {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
}

.gallery-image {
  max-width: 320px; 
  height: auto; 
  margin: 10px 10px;
}
