.payment-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
    min-height: 80vh;
  }
  
  .payment-success-container h1 {
    color: #f6a700;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .payment-success-container p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .purchase-summary {
    background-color: #fff;
    padding: 30px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 30px;
    width: 100%;
    max-width: 600px;
  }
  
  .purchase-summary h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .purchase-summary p {
    margin-bottom: 10px;
    color: #555;
    font-size: 1rem;
  }
  
  .product-details {
    margin-top: 20px;
  }
  
  .product-details h3 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .product-item {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .product-item p {
    margin-bottom: 5px;
    color: #555;
  }
  
  .payment-success-container button {
    background-color: #f6a700;
    color: white;
    border: none;
    padding: 12px 30px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .payment-success-container button:hover {
    background-color: #e5932b;
  }
  
  @media (max-width: 600px) {
    .purchase-summary {
      padding: 20px;
    }
  
    .payment-success-container h1 {
      font-size: 2rem;
    }
  
    .payment-success-container p {
      font-size: 1rem;
    }
  }
  