:root {
    --dorado-suave: #D4AF37;
    --beige-claro: #F5F5DC;
    --blanco-roto: #FAF9F6;
    --gris-perla: #C0C0C0;
    --text-color: #282828;
    --brand-hover: #B8982B;
  }
  
  .container {
    margin: 0 auto;
    padding: 16px;
    max-width: 600px;
  }
  
  .login-box {
    background-color: var(--blanco-roto);
    padding: 20px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .icon-container {
    width: 80px;
    height: 80px;
    margin: 16px auto;
  }
  
  .icon-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .form-container {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .input-group {
    display: grid;
  }
  
  .input-wrapper {
    padding: 4px;
    border-radius: 4px;
    background-color: var(--beige-claro);
  }
  
  .password-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .input-field {
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 8px;
    font-size: 16px;
    color: var(--text-color);
  }
  
  .toggle-password {
    cursor: pointer;
    font-size: 24px;
    color: var(--dorado-suave);
  }
  
  .forgot-password-link {
    display: block;
    text-align: right;
    margin-top: 8px;
    color: var(--dorado-suave);
    text-decoration: none;
  }
  
  .forgot-password-link:hover {
    text-decoration: underline;
    color: var(--brand-hover);
  }
  
  .login-button {
    background-color: var(--dorado-suave);
    color: var(--blanco-roto);
    padding: 12px 24px;
    width: 100%;
    max-width: 150px;
    border-radius: 9999px;
    margin: 24px auto 0;
    display: block;
    transition: all 0.3s ease;
    text-align: center;
    border: none;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: var(--brand-hover);
    transform: scale(1.05);
  }
  
  .signup-text {
    margin-top: 20px;
    text-align: center;
  }
  
  .signup-link {
    color: var(--dorado-suave);
    text-decoration: none;
  }
  
  .signup-link:hover {
    text-decoration: underline;
    color: var(--brand-hover);
  }
  