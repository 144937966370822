/* FormContact.css */

/* Contenedor principal del formulario */
form {
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Título del formulario */
  form h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333333;
    text-align: center;
  }
  
  /* Estilos para las etiquetas */
  form label {
    display: block;
    font-size: 1rem;
    color: #555555;
    margin-bottom: 0.5rem;
  }
  
  /* Estilos para los campos de entrada */
  form input[type="text"],
  form input[type="email"],
  form textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    margin-bottom: 1rem;
    box-sizing: border-box;
  }
  
  /* Efecto de enfoque en los campos */
  form input[type="text"]:focus,
  form input[type="email"]:focus,
  form textarea:focus {
    border-color: #D4AF37;
    outline: none;
  }
  
  /* Estilo específico para el área de texto */
  form textarea {
    resize: vertical;
    min-height: 120px;
  }
  
  /* Estilos para el botón de envío */
  form button {
    width: 100%;
    padding: 0.75rem;
    background-color: #D4AF37;
    color: #1a1a1a;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Efecto al pasar el cursor sobre el botón */
  form button:hover {
    background-color: #f1c432;
  }
  
  /* Responsividad para tabletas */
  @media (max-width: 768px) {
    form {
      padding: 1.5rem;
    }
  
    form h2 {
      font-size: 1.75rem;
    }
  
    form button {
      font-size: 1rem;
    }
  }
  
  /* Responsividad para móviles */
  @media (max-width: 480px) {
    form {
      padding: 1rem;
    }
  
    form h2 {
      font-size: 1.5rem;
    }
  
    form button {
      font-size: 0.95rem;
    }
  }
  