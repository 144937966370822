/* Variables de color y estilos base */
:root {
  --dorado-suave: #D4AF37;
  --beige-claro: #F5F5DC;
  --blanco-roto: #FAF9F6;
  --gris-perla: #C0C0C0;
  --text-color: #333;
  --font-family: 'Arial', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Sección principal */
.productos-section {
  background-color: var(--beige-claro);
  padding: 60px 20px;
}

/* Contenedor centrado y con ancho máximo */
.productos-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Encabezado de la sección */
.productos-header {
  text-align: center;
  margin-bottom: 40px;
}

/* Badge para el encabezado */
.productos-badge {
  display: inline-block;
  color: #282828;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Título principal */
.productos-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--text-color);
  font-family: var(--font-family);
}

/* Descripción centrada */
.productos-description {
  font-size: 1.125rem;
  line-height: 1.6;
  color: var(--text-color);
  max-width: 800px;
  margin: 0 auto;
  font-family: var(--font-family);
}

/* Contenedor de tarjetas */
.vertical-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 20px;
}

/* Media queries para responsividad */
@media (max-width: 768px) {
  .productos-heading {
    font-size: 2rem;
  }
  
  .productos-description {
    font-size: 1rem;
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .productos-heading {
    font-size: 1.75rem;
  }
  
  .productos-badge {
    font-size: 0.8rem;
    padding: 4px 10px;
  }
}
