
.cancelPayment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px auto;
    max-width: 600px;
    padding: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .cancelPayment-container h2 {
    font-size: 1.8rem;
    margin-bottom: 16px;
    color: #d00; 
  }
  
  .cancelPayment-container p {
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
    margin-bottom: 24px;
  }
  
  .cancelPayment-btn {
    padding: 12px 24px;
    font-size: 1rem;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cancelPayment-btn:hover {
    background-color: #e53935; 
  }
  