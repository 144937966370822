:root {
  --dorado-suave: #D4AF37;
  --beige-claro: #F5F5DC;
  --blanco-roto: #FAF9F6;
  --gris-perla: #C0C0C0;
  --text-color: #282828;
  --brand-hover: #B8982B;
}

.header {
  height: 5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 40;
}

.header-container {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.search-input {
  width: 300px;
  padding: 10px;
  font-size: 16px;
  border-radius: 2px;
  margin-right: 10px;
  border: 1px solid var(--gris-perla);
}

.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--beige-claro);
  color: var(--text-color);
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: var(--dorado-suave);
  color: var(--blanco-roto);
}

.navigation-menu-horizontal {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigation-menu-horizontal li {
  margin: 0 15px;
}

.navigation-menu-horizontal li a {
  text-decoration: none;
  color: var(--text-color);
  padding: 10px;
  transition: color 0.3s ease;
}

.navigation-menu-horizontal li a:hover {
  color: var(--dorado-suave);
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 1.75rem;
}

.user-menu {
  position: relative;
  display: flex;
  justify-content: center;
}

.user-icon {
  font-size: 1.875rem;
  cursor: pointer;
  color: var(--text-color);
  display: flex;
  justify-content: center;
}

.profile-pic {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
}

.menu-dropdown {
  position: absolute;
  background-color: var(--blanco-roto);
  top: 2.75rem;
  padding: 0.5rem;
  color: var(--text-color);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
}

.menu-dropdown a {
  white-space: nowrap;
  display: none;
  text-transform: lowercase;
  padding: 0.5rem;
  text-decoration: none;
  color: var(--text-color);
}

.menu-dropdown a:hover {
  background-color: var(--beige-claro);
}

@media (min-width: 768px) {
  .menu-dropdown a {
    display: block;
    text-transform: lowercase;
  }
}

.MenuItem {
  text-transform: lowercase;
}

.motos-button {
  font-size: 1rem;
  color: var(--text-color);
  padding: 0.75rem 1.5rem;
  background-color: var(--dorado-suave);
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  display: inline-block;
  text-transform: lowercase;
  margin: 1rem 0;
  text-align: center;
}

.motos-button:hover {
  background-color: var(--brand-hover);
}

.ver-motos {
  text-align: center;
}

.cart-link {
  font-size: 1.5rem;
  position: relative;
  color: var(--text-color);
}

.cart-count {
  background-color: var(--dorado-suave);
  color: var(--blanco-roto);
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 4px;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -0.5rem;
  right: -0.75rem;
  font-size: 0.875rem;
}

.auth-button a,
.auth-button button {
  padding: 0.25rem 0.75rem;
  color: var(--text-color);
  border-radius: 4px;
  background-color: var(--dorado-suave);
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-button a:hover,
.auth-button button:hover {
  background-color: var(--brand-hover);
}

@media (min-width: 1024px) {
  .search-bar {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 384px;
    border-radius: 8px;
    padding-left: 0.5rem;
  }
  .search-bar:focus-within {
    box-shadow: 0 0 0 3px rgba(184, 185, 186, 0.5);
  }
}
